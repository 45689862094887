import { ThemeProvider, Typography } from "@material-ui/core";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import theme from "../theme";

const MissionPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Missie" />

        <Typography data-aos="fade-right" variant="h5" style={{ marginBottom: 30, marginTop: 20 }}>
          Achtergrond
        </Typography>

        <Typography data-aos="fade-right" variant="subtitle1" style={{ marginBottom: 30 }}>
          Door mijn jarenlange <strong>ervaring als advocaat</strong> met
          echtscheidingsprocedures en procedures aangaande het ouderlijk gezag
          en de verblijfsregeling voor kinderen voor de familierechtbank kwam ik
          tot de vaststelling dat mensen na afloop van dergelijke procedures
          dikwijls met een wrang gevoel bleven zitten. Veel mensen hadden het
          gevoel dat er niet naar hen werd geluisterd en dat alles achter hun
          rug werd beslist door hun advocaten en de familierechtbank. Tevens
          voelden mensen zich regelmatig 'de verliezer' ondanks het feit dat de
          familierechtbank een beslissing in hun voordeel had genomen.
        </Typography>

        <Typography data-aos-delay="300" data-aos="fade-left" variant="h5" style={{ marginBottom: 30 }}>
          Focus op dialoog
        </Typography>

        <Typography data-aos-delay="300" data-aos="fade-left" variant="subtitle1" style={{ marginBottom: 30 }}>
          De reden hiervoor ligt volgens mij in het gebrek aan dialoog, waardoor
          dergelijke gerechtelijke procedures worden gekenmerkt. Het conflict
          wordt boven de hoofden van de mensen gevoerd en de mensen krijgen
          slechts beperkt het woord op de zitting voor de familierechter. Dit is
          dan ook de reden waarom ik veel <strong>belang hecht aan overleg</strong>. Ik vind het
          immers in het <strong>belang van het kind en de partijen</strong> zelf
          dat er communicatie mogelijk blijft tussen beide partners. We
          verliezen te vaak uit het oog dat kinderen het grootste slachtoffer
          zijn van echtelijke moeilijkheden die zich voordoen tussen hun ouders.
          De gevolgen voor de kinderen kunnen groot zijn: angstgevoelens,
          hechtingsproblemen en moeilijkheden op school zijn jammer genoeg veel
          voorkomend. Ik bied hiervoor een ideale oplossing.
          Ik ga met beide partners in gesprek om op de best mogelijke manier
          voor het kind en de partners zelf een regeling aangaande de
          echtscheiding en het verblijf van het kind uit te werken. Ik heb
          hierbij <strong>oor naar de standpunten van beide partijen</strong> en
          probeer deze standpunten met elkaar te <strong>verzoenen</strong>.
        </Typography>

        <Typography data-aos-delay="600" data-aos="fade-right"  variant="h5" style={{ marginBottom: 30 }}>
          Concilium - Uw bemiddelingspartner
        </Typography>
        <Typography data-aos-delay="600" data-aos="fade-right"  variant="subtitle1" style={{ marginBottom: 30 }}>
          Daarnaast geef ik u <strong>(juridisch) advies</strong> indien
          u met praktische en emotionele vragen zit omtrent uw gezinssituatie:
          wettelijke kaders, informatie over de emotionele en praktische
          gevolgen van een echtscheiding, duiding... U kunt rekenen op <strong> een empathische, vertrouwelijke en toegankelijke manier van werken
            binnen een professioneel kader
          </strong>
          . Door mijn jarenlange ervaring als familierechtadvocaat kunt u
          bovendien op uw beide oren slapen dat uw dossier op een wettelijk
          sluitende manier wordt behandeld.
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
);

export default MissionPage;
